<template>
  <v-app id="main">
    <v-navigation-drawer v-model="drawer" app>
      <!--  -->
      <v-list-item class="px-2">
        <v-btn icon>
          <v-icon class="icon-menu" @click="drawer = !drawer"
            >mdi-menu-open</v-icon
          >
        </v-btn>
      </v-list-item>

      <v-list>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="item.to"
          v-model="pageSelect"
          @click="pageSelect = item.title"
        >
          <v-list-item-icon>
            <v-icon class="icon-menu">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="icon-menu">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="white">
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        v-show="!drawer"
      ></v-app-bar-nav-icon>

      <v-toolbar-title>{{ pageSelect }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon>mdi-bell-outline</v-icon>
      </v-btn>

      <BadgeCustom fullName="" signName="B" class="avata-header" />
    </v-app-bar>

    <v-main>
      <!--  -->
      <router-view></router-view>
    </v-main>
  </v-app>
</template>


<script>
// import HelloWorld from './components/HelloWorld';
import BadgeCustom from "./components/Badge/BadgeCustom";
// import SelectCustom from "./components/Selection/SelectCustom";
// import Calendar from "./pages/Calendar";

export default {
  name: "App",

  components: {
    BadgeCustom,
    // SelectCustom,
    // Calendar,
  },

  data: () => ({
    drawer: null,
    pageSelect: "Home",
    items: [
      { title: "Home", icon: "mdi-home-city", to: "/" },
      {
        title: "Calendar",
        icon: "mdi-calendar-blank-outline",
        to: "/calendar",
      },
      { title: "Sales", icon: "mdi-account-group-outline", to: "/sales" },
      {
        title: "Vouchers",
        icon: "mdi-ticket-percent-outline",
        to: "/vouchers",
      },
      { title: "Clients", icon: "mdi-account", to: "/clients" },
      { title: "Staff", icon: "mdi-briefcase-variant-outline", to: "/staff" },
      { title: "Services", icon: "mdi-account-group-outline", to: "/services" },
      {
        title: "Inventory",
        icon: "mdi-account-group-outline",
        to: "/inventory",
      },
      {
        title: "Analytics",
        icon: "mdi-account-group-outline",
        to: "/analytics",
      },
      { title: "Setup", icon: "mdi-cog-outline", to: "/setup" },
    ],
    mini: true,
  }),
    watch: {
		'$route' () {
			this.updatePageHeader();
		}
	},

	mounted() {
		this.updatePageHeader();
	},

	methods: {
		updatePageHeader() {
            let obj = this.items.filter(o => o.to == this.$route.path);
            obj = JSON.parse(JSON.stringify(obj))
			this.pageSelect = obj[0].title;
		}
	}
};
</script>

<style scoped>
#main .v-navigation-drawer {
  background-color: #1a263a;
  color: white;
}
.icon-menu {
  color: white !important;
}
#main .v-toolbar__title {
  font-weight: bold;
}
.avata-header {
  margin-top: 18px;
}
.v-app-bar {
  box-shadow: none;
}
.v-toolbar__content {
  border-bottom: 1px solid #e1e5e9;
}
</style>
